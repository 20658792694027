.badge {
  line-height: 16px;
}

.badge-secondary {
  background-color: $gray-200;
  color: $gray-900;
}

.badge-transparent {
  border-radius: 20px;
  color: $gray-900;
  padding-left: 16px;
  padding-right: 16px;

  &.badge-info {
    background: rgba(71, 43, 174, 0.2);
  }

  &.badge-secondary {
    background: $gray-800;
    color: $white;
  }

  &.badge-warning {
    background: rgba(241, 194, 27, 0.2);
  }

  &.badge-success {
    background: rgba(43, 125, 43, 0.2);
  }

  &.badge-danger {
    background: rgba(187, 0, 0, 0.2);
  }
}
