@import "@force/theme/src/variables";

.card {
  box-shadow: 0 4px 10px rgba(11, 48, 56, 0.15);

  .card-img,
  .card-img-top {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .card-img,
  .card-img-bottom {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .card-footer {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding-bottom: 16px !important;
    padding-top: 16px !important;

    &.with-accordion {
      padding: 0 !important;
    }
  }

  .card-footer-link {
    background: $white !important;
    border-top: 1px solid $gray-200 !important;
    color: $cyan;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
  }

  .card-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    min-height: 47px;
  }

  .card-title {
    font-weight: $font-weight-semi-bold;
  }

  .list-group {
    margin-top: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  .list-group-item {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    margin-top: 1rem;
    padding-bottom: 1rem;

    .end-arrow {
      font-size: 24px;
    }
  }
}

.ghost-card {
  background: $gray-200;
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;
  text-align: center;
  width: 22rem;
}
