.btn {
  letter-spacing: 0.03em;
  line-height: 22px;
  min-height: 40px;
}

.btn-lg {
  font-weight: $font-weight-bold;
  min-height: 40px;
}

.btn {
  &:disabled,
  &.disabled {
    background-color: $gray-200 !important;
    border-color: transparent !important;
    pointer-events: none !important;
  }
}

.btn-primary {
  border-color: transparent !important;

  &.bg-dark {
    background-color: $green !important;
    color: $gray-900 !important;

    &:hover {
      background-color: #a2e5ab !important;
    }

    &:disabled {
      background-color: #517a62 !important;
      color: $gray-900 !important;
    }
  }

  &:hover {
    background-color: $light-cyan !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    background-color: $gray-200 !important;
    border-color: none !important;
    color: $gray-700 !important;
  }
}

.btn-secondary {
  background: transparent;
  border-color: transparent !important;
  color: $cyan !important;

  &.bg-dark {
    color: $white !important;

    &:hover {
      background-color: $white !important;
      color: $gray-900 !important;
    }

    &:disabled {
      color: $gray-700 !important;
    }
  }

  &:hover {
    background-color: $cyan !important;
    color: $white !important;
  }

  &:disabled {
    background: transparent !important;
    border-color: transparent !important;
    color: $gray-700 !important;
  }

  &:focus {
    background-color: unset !important;
    border-color: transparent !important;
    box-shadow: none !important;

    &:hover {
      background-color: $cyan !important;
      color: $white !important;
    }
  }
}

.btn-dark {
  &:hover {
    background-color: $body-bg;
    color: $dark;
  }

  &:active {
    background-color: $dark;
    color: $body-bg;
  }

  &:focus {
    background-color: $dark;
    box-shadow: none !important;
    color: $body-bg;
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-color: none !important;
    color: rgba(0, 0, 0, 0.38) !important;
  }

  &:disabled {
    background-color: $gray-200 !important;
    border-color: none !important;
    color: $gray-700 !important;
  }
}

.btn-link {
  &.green {
    color: $green;

    &:hover {
      color: $green;
    }
  }

  color: $cyan;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-outline-secondary {
  border-color: transparent !important;
}

.btn-outline-danger {
  border-color: rgba(50, 58, 69, 0.25);

  &:hover {
    background-color: #f4f5f5;
    border-color: #cbcdd0;
    color: #c65c60;
  }
}
