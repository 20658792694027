@import "./variables";

.dropdown-menu {
  border-radius: 4px !important;
  font-size: 1rem;
  padding: 0;
  width: auto;
  z-index: 1020;

  .dropdown-item {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    white-space: normal !important;

    &.active,
    &:active {
      background-color: $gray-200;
      color: $gray-900;
    }
  }
}

.dropdown-header {
  font-weight: $font-weight-bold;
}

.icon-dropdown {
  width: 200px !important;

  .btn {
    width: 200px;
  }
}
