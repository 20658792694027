.breadcrumb-item > a {
  color: #fff;
  text-decoration: underline;
}

.dark-breadcrumb {
  color: $primary !important;

  .breadcrumb-item > a,
  .breadcrumb-item.active,
  .breadcrumb-item + .breadcrumb-item::before {
    color: $primary !important;
    font-size: 14px;
  }
}

.breadcrumb-lg {
  font-weight: $font-weight-bold;
}
