@import "@force/theme/src/variables";

.nav-tabs {
  border-bottom: 1px solid $gray-200 !important;
  flex-wrap: nowrap !important;
  margin-bottom: 16px;
  overflow-y: hidden;

  a {
    align-items: center;
    color: $gray-900;
    display: flex;
    font-size: 1rem;
    height: 56px;
    justify-content: center;
    min-width: max-content;
  }

  .nav-item:first-child {
    margin-left: 16px;
  }

  .nav-item {
    margin-left: 1rem;

    &.active,
    > .nav-link.active {
      background-color: inherit;
      border-bottom: 4px solid $orange;
      font-weight: $font-weight-bold;
      padding-top: 8px;
    }
  }

  .nav-link {
    border: 0;
  }
}
