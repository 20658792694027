.layout-wrapper {
  display: flex;
  width: 100%;
}

.sidebar {
  color: $white;
  max-width: $sidebar-width !important;
  position: fixed;
  transition: all 0.3s;
  z-index: 1030;

  @media (max-width: 768px) {
    position: absolute;

    .navbar-collapse.show .navbar-nav {
      height: calc(100vh - #{50px}) !important;
    }
  }

  .navbar-collapse {
    background-color: $sidebar-bg !important;
  }

  .navbar-nav {
    height: 100vh;
    width: $sidebar-width !important;

    a {
      &:not(.logo) {
        border-radius: 3px;
        display: flex;
        font-size: 16px;
        font-weight: $font-weight-bold;
        line-height: 24px;
        margin: 0 8px;
        padding: 8px 0;
        text-decoration: none;

        &.xl {
          height: 60px;
        }

        &:hover {
          background-color: $sidebar-item-hover-bg;
        }
      }
    }
  }

  .bg-orange {
    background: $orange;
    border-radius: 4px;
    visibility: hidden;
    width: 4px;
  }

  .layout--sidebar-header {
    background: $white;
    border-radius: 10px;
    min-height: 80px;

    .logo {
      align-self: center;
      display: flex;
      justify-content: center;
    }

    img {
      height: auto;
      max-width: 75%;
    }
  }

  .sidebar__active_tab {
    background-color: $sidebar-item-hover-bg;

    .bg-orange {
      visibility: visible;
    }
  }

  .bg-orange {
    background: $orange;
    border-radius: 4px;
    visibility: hidden;
    width: 4px;
  }

  .footer-text {
    padding-top: 12px;
  }
}

.navbar-toggler {
  margin: 10px;
}

.banner-container {
  position: absolute;
  width: 100%;
}

.layout--content {
  background-color: $bg-gray;
  height: 90vh;
  min-height: 90vh;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .layout-wrapper {
    .layout--sidebar {
      margin-left: -$sidebar-width;
    }

    .layout--sidebar.active {
      margin-left: 0;
    }

    .layout--content {
      width: 100%;
    }

    .layout--content.active {
      width: calc(100% - #{$sidebar-width});
    }
  }
}

.fs-12 {
  font-size: 12px;
  line-height: 16px;
}

.fs-14 {
  font-size: 14px;
  line-height: 20px;
}

.fs-16 {
  font-size: 16px;
  line-height: 24px;
}

.fs-18 {
  font-size: 18px;
  line-height: 24px;
}

.fs-20 {
  font-size: 20px;
  line-height: 28px;
}

.fs-24 {
  font-size: 24px;
  line-height: 32px;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.bg-gray {
  background-color: $bg-gray;
}

.text-green {
  color: $green;
}

.text-orange {
  color: $orange;
}

.text-yellow {
  color: $yellow;
}

.text-blue {
  color: $blue;
}

.grey-icon {
  color: $gray-700;
}
