@import "@force/theme/src/variables";

$color-primary: #007bff;
$color-disabled: #495057;
$color-white: #fff;

// Hide IE's native "clear" button
.rbt .rbt-input-main::-ms-clear {
  display: none;
}

/**
 * Menu
 */
.rbt-menu {
  margin-bottom: 2px; // Spacing for dropup

  > .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  &-pagination-option {
    text-align: center;
  }
}

/**
 * Multi-select Input
 */
$background-color-disabled: #e9ecef;

$border-color-focus: #80bdff;
$border-color-focus-invalid: #dc3545;
$border-color-focus-valid: #28a745;

$box-shadow-dimensions: 0 0 0 0.2rem;
$box-shadow-color: rgba(0, 123, 255, 0.25);
$box-shadow-color-invalid: rgba(220, 53, 69, 0.25);
$box-shadow-color-valid: rgba(40, 167, 69, 0.25);

$color-focus: #495057;
$placeholder-color: #6c757d;

.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;

  // Apply Bootstrap focus styles
  &.focus {
    border-color: $border-color-focus;
    box-shadow: $box-shadow-dimensions $box-shadow-color;
    color: $color-focus;
    outline: 0;
  }

  &.form-control {
    height: auto;
  }

  // BS4 uses the :disabled pseudo-class, which doesn't work with non-inputs.
  &.form-control[disabled] {
    background-color: $background-color-disabled;
    opacity: 1;
  }

  &.is-invalid.focus {
    border-color: $border-color-focus-invalid;
    box-shadow: $box-shadow-dimensions $box-shadow-color-invalid;
  }

  &.is-valid.focus {
    border-color: $border-color-focus-valid;
    box-shadow: $box-shadow-dimensions $box-shadow-color-valid;
  }

  .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
  }

  .rbt-input-main {
    margin: 1px 0 4px;
  }
}

/**
 * Close Button
 */
.rbt-close {
  z-index: 1;

  &-lg {
    font-size: 24px;
  }
}

/**
 * Token
 */
$token-background-color: $gray-200;
$token-color: $gray-900;

$token-disabled-background-color: rgba(0, 0, 0, 0.1);
$token-disabled-color: $color-disabled;

$token-active-background-color: $color-primary;
$token-active-color: $color-white;

.rbt-token {
  background-color: $token-background-color;
  border: 0;
  border-radius: 0.25rem;
  color: $token-color;
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  position: relative;

  &-invalid {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;

    .rbt-token-remove-button {
      color: $red !important;
    }
  }

  &-disabled {
    background-color: $token-disabled-background-color;
    color: $token-disabled-color;
    pointer-events: none;
  }

  &-removeable {
    cursor: pointer;
    padding-right: 21px;
  }

  &-active {
    background-color: $token-active-background-color;
    color: $token-active-color;
    outline: none;
    text-decoration: none;
  }

  & &-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
  }
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  pointer-events: none; /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;

  .rbt-close {
    margin-top: -4px;
    pointer-events: auto; /* Override pointer-events: none; above */
  }
}

.has-aux .rbt-input {
  padding-right: 34px;
}

// Default highlight style
.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
  flex: 1;

  // Form-controls within input-groups have a higher z-index.
  .rbt-input-hint,
  .rbt-aux {
    z-index: 5;
  }

  &:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.rbt {
  .rbt-close {
    color: $gray-900;
    font-size: $font-size-lg;
    opacity: 1;
    padding-left: 6px;
    padding-top: 2px;
  }
}
