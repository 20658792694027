.eula-text {
  font-size: 12px;
}

.eye-icon {
  color: $gray-500;
  cursor: pointer;
  font-weight: 900;
  left: -0.5rem;
  position: relative;
  text-align: center;
  top: 2.25rem;
  width: 20px;
}

.eye-icon-container {
  height: 0 !important;
  text-align: right !important;
}
