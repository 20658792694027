//put your variables here
@import "./variables";

//main bootstrap import
@import "~bootstrap/scss/bootstrap";
@import "@mobiscroll/react/dist/css/mobiscroll";

body {
  font-family: $font-family-sans-serif;
}

* {
  box-sizing: border-box;
}

//put your customization here preferably in a per component scss file
//just uncomment and start customization
@import "./reboot";
//@import "./functions";
//@import "./type";
@import "./images";
//@import "./code";
//@import "./grid";
//@import "./tables";
@import "./forms";
@import "./buttons";
//@import "./transitions";
@import "./dropdown";
//@import "./button-group";
//@import "./input-group";
//@import "./custom-forms";
//@import "./nav";
@import "./navbar";
@import "./card";
@import "./breadcrumb";
//@import "./pagination";
@import "./badge";
//@import "./jumbotron";
@import "./alert";
@import "./progress";
//@import "./media";
@import "./list-group";
//@import "./close";
//@import "./toasts";
@import "./modal";
//@import "./tooltip";
//@import "./popover";
//@import "./carousel";
//@import "./spinners";
//@import "./utilities";
//@import "./print";
@import "./layout";
@import "./tabs";

@import "./multiselect";
