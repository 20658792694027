.navbar-brand {
  font-weight: $font-weight-bold;
  line-height: 28px;
}

.navbar {
  min-height: 56px;
  width: 100%;

  &.bg-transparent {
    border-bottom: 1px solid $gray-200;
  }

  .input-group {
    width: 260px;
  }

  svg {
    font-size: 16px;
  }

  small,
  .breadcrumb {
    font-size: $font-size-base;
    line-height: 1;
  }
}

.navbar-dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .navbar-brand {
    color: $body-bg;
  }
}

.nav-item {
  .dropdown-toggle.nav-link {
    border-radius: $border-radius;
    float: right;
    text-align: center;
    width: 32px;

    &::after {
      content: none;
    }

    svg {
      color: $body-bg;
      font-size: $font-size-lg;
    }
  }
}
