.alert-banner {
  border-width: 2px;
  color: $gray-900 !important;
  left: 0;
  margin: auto;
  margin-top: 10%;
  right: 0;
  width: fit-content;
  z-index: 9999;

  .clear-both {
    clear: both;
  }

  &.alert-dismissible .close {
    opacity: 1;
    padding-right: 0.5rem;
    padding-top: 2px;
  }

  &__extra {
    margin-top: $spacer * 0.5;
  }

  &__arrow {
    color: $gray-800 !important;
  }

  .alert-link {
    text-decoration: underline;
  }

  svg {
    font-size: 16px !important;
  }

  &--action {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &.alert-success {
    .close {
      color: $success;
    }
  }

  &.alert-info,
  &.alert-primary {
    .close {
      color: $info;
    }
  }

  &.alert-danger {
    .close {
      color: $red;
    }
  }
}
