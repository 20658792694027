.modal {
  .infinite-scroll-component {
    overflow: visible !important;
  }
}

.modal-body {
  padding-bottom: 0;
  padding-top: 0;
}

.modal-header {
  font-size: $font-size-lg;
  font-weight: $font-weight-semi-bold;

  padding-top: 2rem;
}

.modal-subtitle {
  font-size: $font-size-base;
  font-weight: normal;
}

.modal-selected-subtitle {
  font-weight: $font-weight-normal;
  padding-bottom: 1rem;
}

.modal-footer {
  padding: 1rem 2rem 2rem;

  .btn:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.list-modal-item {
  align-items: center;
  border-bottom: 1px solid $gray-200;
  display: flex;
  justify-content: flex-start;
  min-height: 2.75rem;
  word-wrap: break-word;
}

.fade.modal-backdrop.confetti-opacity.show {
  opacity: 0.075;
}
