@import "@force/theme/src/variables";
@import "@fortawesome/fontawesome-free/scss/fontawesome";

@import "./common";

/* sticky footer */
html {
  min-height: 100%;
  position: relative;
}

body {
  background-color: #54a0b4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  height: 150px;
  line-height: 80px;
  margin-top: auto;
  width: 100%;
}

body > .container {
  padding-top: 9rem;
}

footer > .container {
  padding-left: 15px;
  padding-right: 15px;
}

code {
  font-size: 80%;
}

.login .alert-heading {
  font-size: $font-size-base;
  font-weight: $font-weight-semi-bold;
}

.asteriskField {
  display: none;
}

.logo {
  width: 150px;
}

.remember-subtext {
  color: $gray-700;
  font-size: $font-size-sm;
}

.forgot-password-input-holder > .form-group > label.requiredField {
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
}

.forgot-password-h1 {
  color: $gray-900;
  font-weight: $font-weight-semi-bold;
  line-height: 32px;
  margin: 0;
}

.dont-worry-text {
  font-weight: $font-weight-normal;
  line-height: 24px;
}

.remember-text {
  color: $gray-800;
  font-weight: $font-weight-normal;
  line-height: 16px;
}
