@import "./variables";

legend {
  font-size: $font-size-md;
  font-weight: $font-weight-semi-bold;
}

input[type="checkbox"] {
  + label {
    cursor: pointer;
    user-select: none;
  }
}

.form-group.required .form-label::before {
  color: $red;
  content: "* " / "";
}

.form-check-inline {
  display: inline;
  padding-left: 0;
}

.form-check {
  &.rounded input[type="checkbox"] {
    &.custom-control-input {
      width: 1.4rem !important;
    }

    &.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $green !important;
    }

    &:checked + label {
      &::before {
        background-color: $green !important;
        border-color: $green !important;
      }
    }

    + label {
      &::before {
        border-radius: 50%;
        height: 20px;
        top: 0 !important;
        width: 20px;
      }

      &::after {
        height: 1.75rem;
        left: -1.7575rem;
        top: -0.2rem !important;
        width: 1.75rem;
      }
    }

    &:disabled {
      + label {
        color: $gray-900 !important;

        &::before {
          background-color: $white;
        }
      }
    }
  }
}

:disabled {
  &:checked + label::before {
    background-color: $gray-200 !important;
    border-color: $gray-500 !important;
  }

  + label {
    pointer-events: none;
  }
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 50% !important;

  &.radio-row-danger {
    background: $danger;
  }

  &.radio-row-success {
    background: $success;
  }
}

.readonly-label {
  font-size: $font-size-base;
  font-weight: $font-weight-semi-bold;
}

.readonly-input {
  font-size: 1rem;
  font-weight: $font-weight-normal;
}

.input-width__mini {
  max-width: 56px;
}

.input-width__xs {
  max-width: 80px;
}

.input-width__sm {
  max-width: 160px;
}

.input-width__md {
  max-width: 240px;
}

.input-width__lg {
  max-width: 320px;
}

.input-width__xl {
  max-width: 480px;
}

.input-icon {
  color: $gray-500;
}

.ghost-row {
  background-color: #fafafa;
  border: 1px dashed #ccc;
  border-radius: $border-radius;
  min-height: 48px;

  button {
    padding-left: 0.4325rem;
  }
}

.form-control:disabled {
  border-color: $gray-500;
  color: $gray-700;
}

.custom-switch-lg {
  padding-left: 4rem;

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: scale(2) translateX(0.6rem);
  }

  .custom-control-label {
    padding-left: 1.5rem;

    &::before {
      transform: scale(2);
    }

    &::after {
      left: calc(-2.25rem + -2px);
      transform: scale(2);
    }
  }
}

.input-group-text {
  color: $gray-500;
}
